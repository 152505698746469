import React, { useState, useEffect, useContext, useRef } from 'react';
import { Row, Col, Table, Form, Container, Card, ListGroup, Dropdown, Button, Tooltip, OverlayTrigger, Nav, Tab, InputGroup } from 'react-bootstrap';
import { MultiSelect } from 'react-multi-select-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash  } from '@fortawesome/pro-regular-svg-icons';
import Select from 'react-select'
import { toast } from 'react-toastify';
import { useConfig } from 'utilities/variables/configContext';

import { 
  expandIcon,
  accountIcon,
  prodAdd,
  genericUserIcon,
  deleteIcon,
  windowsLogo,
  ubuntuLogo,
  kaliLogo,
  jitsiLogo,
  mandiantFlareLogo
} from './../../assets/images';

import {
  imageMap,
} from '../../pages/generic/Dashboard/data';

import * as utilComponents from './../utilities'

import { 
  DEFAULT_API_RESULT
} from '../../utilities/variables';

import {
  variables,
  helpers,
  types,
  api,
  authContextHelper
} from 'utilities';

import {ConfirmReleaseModal} from '../modals'

import { CiPalette } from 'react-icons/ci';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { integer } from 'aws-sdk/clients/cloudfront';


const AdminGroupsContentEditFormPane: React.FunctionComponent<any> = (props: any) => {
  const [editFormBody, setEditFormBody] = useState<any>({});
  const [isGroupNameValid, setGroupNameValid] = useState(true);
  const [isGroupDescriptionValid, setGroupDescriptionValid] = useState(true);
  const [groupUsers, setGroupUsers] = useState<any>([]);
  const [options, setOptions] = useState<{ label: string; value: string }[]>([]);
  const [userList, setUserList] = useState<types.IUserListAttributes[]>([]); 
  const [groupOwner, setGroupOwner] = useState<string>('');

  const [getShareUserStatus, setGetShareUserStatus] = useState<boolean>(false);
  const [setShareUserStatus, setSetShareUserStatus] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);
  const [hasAdded, setHasShared] = useState(false);
  
  const [isFormValid, setFormValid] = useState(false);
  const [isFormChanged, setFormChanged] = useState(false);

  const [containerHeight, setContainerHeight] = useState<number>(400); // Default height
  
  const { 
    groupContent, 
    users, 
    editGroupSubmitError,
    handleGroupEditSubmit,
    mediaControlHandlers
  } = props;

  let usersToAdd:any = null;
  let usersToRemove:any = null;


  useEffect(() => {
    setFormChanged(false);
    setEditFormBody({...groupContent});
    setGroupNameValid(true);
    setGroupDescriptionValid(true);

    
    
    
    if (groupContent && groupContent.users) {
      setGroupUsers(mapUserIdsToUsers(groupContent.users));
    } else {
      setGroupUsers([])
    }
    

  }, [groupContent]);

  useEffect(() => {
    setUserList(users)
  }, [users]);

  useEffect(() => {
    // Transform the user data into options for the dropdown
    const userOptions = userList && userList.map((user) => ({
      label: user.display_name,
      value: user.id,
    }));

    setOptions(userOptions);

    if (groupContent && groupContent.users) {
      setGroupUsers(mapUserIdsToUsers(groupContent.users));
    } else {
      setGroupUsers([])
    }

    if (groupContent && groupContent.owner) {
      setGroupOwner(mapUserIdToDisplayName(groupContent.owner))
    }
    
  }, [userList]);

  const handleInputChange = (event: any) => {
    // Toggle Form has Changed
    setFormChanged(true);

    //
    if(event.target.name === 'name') {
      // event.target.value = event.target.value.split(" ").join("");
      event.target.value = event.target.value.replace(' ', '');
      if (variables.DEFAULT_FORM_VALIDATION.GROUP.NAME.test(event.target.value)) {
        setGroupNameValid(true);
      } else {
        setGroupNameValid(false);
      }
    }

    if(event.target.name === 'description') {
      // event.target.value = event.target.value.split(" ").join("");
      // event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.GROUP.DESCRIPTION.test(event.target.value) || event.target.value === '') {
        setGroupDescriptionValid(true);
      } else {
        setGroupDescriptionValid(false);
      }
    }

    setEditFormBody({
      ...editFormBody,
      [event.target.name]: event.target.value,
    });


    if (isGroupNameValid && isGroupDescriptionValid ){
      setFormValid(true)
    } else {
      setFormValid(false)
    }

  }

  const addGroupUser = (event: any, userId: any, display_name: any, firstname: any) => {
    event.preventDefault();
    

    setGroupUsers([
      ...groupUsers,
      {
        label: display_name,
        sub_label: firstname,
        value: userId
      }
    ]);
    


    setFormChanged(true);
  }

  const removeGroupUser = (event: any, userId: number) => {
    event.preventDefault();
      
    setGroupUsers([...groupUsers.filter((user: any) => user !== userId)]);
    
    setFormChanged(true);
  }

  const validateForm = () => {
    
    return;
  }

  const mapUserIdsToUsers = (userIds: string[]) => {
    
    
    return userIds && userIds.map(userId => {
      const user = userList.find(user => user.id === userId);
      
      if (user) {
        return {
          label: user.display_name,
          sub_label: user.name.first,
          value: userId
        };
      }
      return null;
    }).filter(Boolean); // Remove any null values (in case a user is not found)
  };

  const mapUserIdToDisplayName = (id: string) => {
    
    
    
    const foundUser = userList.find(user => {
      
      return user.id === id;
    });
    
    if (foundUser && foundUser.display_name) {
      return foundUser.display_name;
    } else {
      
      return 'Displayname Not Found';
    }
  };

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    

    validateForm();

    if (isGroupNameValid && isGroupDescriptionValid){
      
      
      try {
        await handleGroupEditSubmit({
          body: {
            ...editFormBody,
            users: [...groupUsers]
          }
        });
      } catch (error) {
        helpers.logToOutput('Error while submitting the form', error);
      }
    } 
  }

  const Tip: React.FC<types.TipProps> = ({ id, children, title }) => (
    <div className='hover-content'>
      <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
        <a href="#">{children}</a>
      </OverlayTrigger>
    </div>
  );


  // Function to update the container height based on window size
  const updateHeight = () => {
    const windowHeight = window.innerHeight;
    const newHeight = windowHeight - 550; // Minus the top and bottom banners etc...
    setContainerHeight(newHeight);
  };

  useEffect(() => {
    updateHeight(); // Set initial height
    window.addEventListener('resize', updateHeight);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  return (
    <>
      <Form onSubmit={handleFormSubmit} key={groupContent.id} className='m-2'>
        <Container className="" >
          
          <Row className="m-0">
            <Col className="col-9 pb-3">
              <Row className="m-0">
                <Col className="col-3 ps-0">
                  <span className='txt-10 color-lightHeader'>Group Name</span>
                  <Form.Group className="p-0 " controlId="paneGroupEditForm.Name">
                    <Form.Control className="p-0 k-inputText k-inputText15 k-bord-col-trans k-outline-none" type="text" placeholder="Name" defaultValue={groupContent.name} name='name'  onChange={handleInputChange}/>
                  </Form.Group>
                </Col>
                <Col className="col-6 ps-0">
                  <span className='txt-10 color-lightHeader'>Description</span>
                  <Form.Group className="p-0 " controlId="paneGroupEditForm.Description">
                    <Form.Control className="p-0 k-inputText k-inputText15 k-bord-col-trans k-outline-none" type="text" placeholder="Description" defaultValue={groupContent.description} name='description' onChange={handleInputChange}/>
                  </Form.Group>
                </Col>
                <Col className="col-3 ps-0">
                  <span className='txt-10 color-lightHeader'>Owner</span>
                  <Form.Group className="p-0 " controlId="paneGroupEditForm.User">
                    <Form.Control disabled className="p-0 k-inputText k-inputText15 k-bord-col-trans k-outline-none" type="text" placeholder="Owner" defaultValue={groupOwner} name='owner'/>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="m-0">
                <Col className="col-11 p-0">
                  {isGroupNameValid ?  null: <small style={{color: 'red'}}>Enter a valid Group Name. {'(5 - 25 Alphanumeric only)'}</small>}
                  {isGroupDescriptionValid ?  null : <small style={{color: 'red'}}>Enter a valid Group Description. {'(Alphabets, Numbers, space and charcaters[-., ()/] accepted)'}</small>}
                </Col>
              </Row>
            </Col>
            <Col className="col-3 p-0 pt-2">
              <Row className="d-flex justify-content-end">
                <div className="p-0 pt-2 d-flex justify-content-end">
                  <Button
                    variant="success"
                    type="submit"
                    disabled={!isGroupNameValid || !isGroupDescriptionValid || !isFormChanged}
                    size="sm"
                    className="k-btn"
                  >
                    Update
                  </Button>
                  <Button
                    variant="danger"
                    style={{ marginLeft: '1%' }}
                    type="button"
                    size="sm"
                    className="k-btn"
                    onClick={(event: any) => {
                      mediaControlHandlers.handleGroupsListDelete(event, {
                        id: groupContent.id,
                        name: groupContent.name,
                      });
                    }}
                  >
                    Remove
                  </Button>
                </div>
              </Row>
              <Row className="m-0 pb-3">
                {editGroupSubmitError ? (
                  <>
                    <small style={{ color: 'red' }}>{editGroupSubmitError}</small>
                  </>
                ) : null}
              </Row>
            </Col>
          </Row>
          <Row className="m-0">
            <Col className="col-6 pb-3">
              <Row className="m-0">
                <Col className='m-0 p-0 col-11'>
                  <span className='txt-10 color-lightHeader ps-0'>Members </span>
                </Col>
                <Col className='m-0 p-0 col-1 k-center-content k-hover b-rad-5'>
                  <Dropdown>
                    <Dropdown.Toggle as={utilComponents.AdminGroupAddUsersDropdownToggle}>
                      <Tip id="t-1" title="Add a user"><AiOutlineUserAdd /></Tip>
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={utilComponents.CustomMenu} className='k-admin-dropdown mb-0 pb-0'>
                      {users && users.map((user: { id: string; display_name: string; name: { first: string } }, index: number) => {
                        return (groupUsers && groupUsers.some((groupUser: { label: string; sub_label: string; value: string }) => groupUser.value === user.id)) ?   
                          null :
                          (
                            <Dropdown.Item 
                              className="k-inputText12" 
                              key={`paneGroupEditForm.Dropdown.${index}`} 
                              onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => addGroupUser(event, user.id, user.display_name, user.name.first)}>
                              {user.display_name}
                            </Dropdown.Item>
                          );
                      })} 
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <hr className="k-border-top-black mt-1 mb-1"></hr>
              </Row>
              <Row>
                <Col className='k-overflow-y-scroll' style={{ height: `${containerHeight}px` }}>
                  {groupUsers && groupUsers.map((user: any, index: number) => (
                    <Row className="m-0 p-0 k-hover-element" key={`paneGroupEditForm.Users.${index}`}>
                      <Col className="col-12 k-inputText k-inputText15-light ps-0 pe-0">
                        <Row className='m-0 mb-2 p-0'>
                          <Col className='col-2 p-0'><img className="k-ctrlImgLrg" src={genericUserIcon}/></Col>
                          <Col className='col-9 p-0 m-0'>
                            <Row className='m-0 p-0 k-mb-m-4'>
                              <Col className='m-0 p-0'>{user.label}</Col>
                            </Row>
                            <Row className='m-0 p-0'>
                              <Col className=' p-0 txt-13 txt-light '>{user.sub_label} </Col>
                            </Row>
                          </Col>
                          <Col className='col-1 p-0 mt-1 mb-1 k-center-content k-hover b-rad-5'>
                            <Tip id="t-1" title="Remove a user">
                              <img className="k-ctrlImgSml" src={deleteIcon} style={{ cursor: 'pointer'}} onClick={(event: any) => removeGroupUser(event, user)}/>
                            </Tip>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  );
}

const AdminUsersContentEditFormPane: React.FunctionComponent<any> = (props: any) => {
  const [editFormBody, setEditFormBody] = useState<any>({});
  const [isDisplayNameValid, setDisplayNameValid] = useState(true);
  const [isRoleValid, setRoleValid] = useState(true);
  const [userGroupIds, setUserGroupIds] = useState<any>([]);
  const [securityGroups, setSecurityGroups] = useState<any>([]);

  const [isPhoneValid, setPhoneValid] = useState(true);
  const [isNameFieldsValid, setNameFieldsValid] = useState(true);
  const [isEmailValid, setEmailValid] = useState(true);
  const [isPasswordValid, setPasswordValid] = useState(true);
  
  const [isFormChanged, setFormChanged] = useState(false);
  
  const { 
    userContent, 
    groups, 
    editUserSubmitError,
    handleUserEditSubmit,
    mediaControlHandlers
  } = props;

  useEffect(() => {
    setFormChanged(false);
    setEditFormBody({...userContent});
    setDisplayNameValid(true);
    setRoleValid(true);
    setPasswordValid(true);
    // setGroupDescriptionValid(true);
    setSecurityGroups(groups)
    setUserGroupIds((userContent && userContent.security) || []);
  }, [userContent.id]);

  const handleInputChange = (event: any) => {
    // Toggle Form has Changed
    setFormChanged(true);

    if(variables.DEFAULT_FORM_NAME_FIELDS.includes(event.target.name)) {
      // event.target.value = event.target.value.split(" ").join("");
      //event.target.value = event.target.value.replace(' ', '');
      if (variables.DEFAULT_FORM_VALIDATION.USER.NAME.test(event.target.value) || event.target.value === '') {
        setNameFieldsValid(true);
      } else {
        setNameFieldsValid(false);
      }
    }
    
    if(event.target.name === 'email') {
      // event.target.value = event.target.value.split(" ").join("");
      event.target.value = event.target.value.replace(' ', '');
      if (variables.DEFAULT_FORM_VALIDATION.USER.EMAIL.test(event.target.value)) {
        setEmailValid(true);
      } else {
        setEmailValid(false);
      }
    }

    if(event.target.name === 'display_name') {
      // event.target.value = event.target.value.split(" ").join("");
      //event.target.value = event.target.value.replace(' ', '');
      if (variables.DEFAULT_FORM_VALIDATION.USER.DISPLAY_NAME.test(event.target.value) || event.target.value === '') {
        setDisplayNameValid(true);
      } else {
        setDisplayNameValid(false);
      }
    }

    if(event.target.name === 'phone') {
      // event.target.value = event.target.value.split(" ").join("");
      event.target.value = event.target.value.replace(' ', '');
      if (variables.DEFAULT_FORM_VALIDATION.USER.PHONE_NUMBER.test(event.target.value) || event.target.value === '') {
        setPhoneValid(true);
      } else {
        setPhoneValid(false);
      }
    }

    if(event.target.name === 'password') {
      // event.target.value = event.target.value.split(" ").join("");
      //event.target.value = event.target.value.replace(' ', '');
      if (variables.DEFAULT_FORM_VALIDATION.USER.PASSWORD.test(event.target.value) || event.target.value === '') {
        setPasswordValid(true);
      } else {
        setPasswordValid(false);
      }
    }

    if(event.target.name === 'role') {
      if ( event.target.value !== '') {
        setRoleValid(true);
      } else {
        setRoleValid(false);
      }
    }


    setEditFormBody({
      ...editFormBody,
      [event.target.name]: event.target.value,
    });

  }

  const addUserGroup = (event: any, groupId: any) => {
    event.preventDefault();

    setUserGroupIds([
      ...userGroupIds,
      groupId
    ]);

    setFormChanged(true);
  }

  const removeUserGroup = (event: any, groupId: number) => {
    event.preventDefault();
      
    setUserGroupIds([...userGroupIds.filter((group: any) => group !== groupId)]);
    
    setFormChanged(true);
  }

  const validateForm = () => {
    return;
  }

  const handleFormSubmit = async(event: any) => {
    event.preventDefault();

    validateForm();

    if (isDisplayNameValid && isEmailValid && isRoleValid ){
      const submitBody = {
        id: userContent.id,
        password: editFormBody['password'],
        name: {
          last: editFormBody['lastName'] || userContent['name']['last'] || '',
          first: editFormBody['firstName'] || userContent['name']['first'] || '',
          middle: editFormBody['middleName'] || userContent['name']['middle'] || '',
        },
        display_name: editFormBody['display_name'] || userContent['display_name'],
        email: editFormBody['email']?.toLowerCase() || userContent['email'],
        phone: editFormBody['phone'] || userContent['phone'],
        role: editFormBody['role'] || userContent['role'],
        profile_photo: {
          avatar: 'default' // Update when photo func available to always keep user's photo
        },
      }

      // Only add password if it's not null
      if (editFormBody['password'] !== null && isPasswordValid) {
        submitBody.password = editFormBody['password'];
      }

      await handleUserEditSubmit({
        body: {
          ...submitBody
        }
      });

      setFormChanged(false)

    } 
  }

  return (
    <>
      <Form onSubmit={handleFormSubmit} key={userContent.id} className='m-2'>
        <Container className="p-0 k-hmh-400px k-overflow-y-scroll">
          
          <Row className="m-0">
            <Col className="col-12 pb-3">
              <Row className="m-0">
                <Col className="col-3 ps-0">
                  <span className='txt-10 color-lightHeader'>Full Name</span>
                  <Form.Group className="p-0 " controlId="paneGroupEditForm.Firstname">
                    <Form.Control className="p-0 k-inputText k-inputText15 k-bord-col-trans k-outline-none" type="text" placeholder="First Name" defaultValue={userContent['name']['first']} name='firstName'  onChange={handleInputChange}/>
                  </Form.Group>
                </Col>
                <Col className="col-3 ps-0">
                  <span className='txt-10 color-lightHeader'>Display Name</span>
                  <Form.Group className="p-0 " controlId="paneGroupEditForm.Firstname">
                    <Form.Control className="p-0 k-inputText k-inputText15 k-bord-col-trans k-outline-none" type="text" placeholder="Display Name" defaultValue={userContent['display_name']} name='display_name'  onChange={handleInputChange}/>
                  </Form.Group>
                </Col>
                <Col className="col-4 ps-0">
                  <span className='txt-10 color-lightHeader'>eMail</span>
                  <Form.Group className="p-0 " controlId="paneGroupEditForm.Firstname">
                    <Form.Control disabled className="p-0 k-inputText k-inputText15 k-bord-col-trans k-outline-none" type="text" placeholder="" defaultValue={userContent['email']} name='email'/>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="m-0">
                <Col className="col-11 p-0">
                  {isNameFieldsValid ?  null: <small style={{color: 'red'}}>Enter a valid User Name(s), lowercase {'(1 - 25 Alphanumeric only)'}</small>}
                  {isDisplayNameValid ?  null: <small style={{color: 'red'}}>Enter a valid Display Name. {'(1 - 25 Alphanumeric only)'}</small>}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="m-0">
            <Col className="col-12 pb-3">
              <Row className="m-0">
                <Col className="col-3 ps-0">
                  <span className='txt-10 color-lightHeader'>Role</span>
                  <Form.Group className="p-0 k-bord-col-trans k-outline-none" controlId="paneUserEditForm.Role">
                    <Form.Select aria-label="Select a role" className="p-0 k-inputSelect k-inputText15-light k-bord-col-trans k-outline-none" name='role' defaultValue={userContent.role} onChange={handleInputChange} >
                      <option value="user" >User</option>
                      <option value="administrator">Admin</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col className="col-3 ps-0">
                  <span className='txt-10 color-lightHeader'>Password</span>
                  <Form.Group className="p-0 " controlId="paneGroupEditForm.Password">
                    <Form.Control className="p-0 k-inputText k-inputText15 k-bord-col-trans k-outline-none" type="password" placeholder="Change password" defaultValue='' name='password'  onChange={handleInputChange}/>
                  </Form.Group>
                </Col>
                <Col className="col-3 ps-0">
                  <span className='txt-10 color-lightHeader'>Status</span>
                  <div className="p-0 k-inputText k-inputText15-light">
                    { userContent.enabled ? 'ENABLED' : 'DISABLED' }
                  </div>
                </Col>
                {/*<Col className="col-4 ps-0">
                  <span className='txt-10 color-lightHeader'>Password</span>
                  <Form.Group className="p-0 " controlId="paneGroupEditForm.Password">
                    <Form.Control type="password" className="p-0 k-inputText k-inputText15 k-bord-col-trans k-outline-none" placeholder="change password" defaultValue="" name='password'/>
                  </Form.Group>
                </Col>*/}
              </Row>
            </Col>
          </Row>

          <Row className="m-0 pb-3">
            <Row className="pe-0">
              <Col className="col-4 p-0 ps-2">
                <div className="pt-2">
                  <Button variant="success" 
                    type="submit"
                    disabled={ !isDisplayNameValid || !isRoleValid || !isEmailValid || !isFormChanged}
                    size="sm" className="k-btn">
                    Update
                  </Button>
                  <Button variant="danger" 
                    style={{marginLeft: '1%'}}
                    type="button"
                    size="sm" className="k-btn"
                    onClick={(event: any)=>{ mediaControlHandlers.handleUsersListDelete(event, {id: userContent.id, name: userContent['name']['first'] || userContent['display_name'] })}}
                  >
                    Remove
                  </Button>
                </div>
              </Col>
            </Row>
            {editUserSubmitError? (<>
              <small style={{color: 'red'}}>{editUserSubmitError}</small>
            </>): null}
          </Row>

        </Container>
      </Form>
    </>
  );
}

export const AdminUsersContentTab: React.FunctionComponent<any> = (props:any) => {
  const { 
    users,
    groups,
    selectedUser, 
    mediaControlHandlers,
    editUserSubmitError,
    handleUserEditSubmit,
    handleShowAddUserModal, 
    handleAdminSelectObject 
  } = props;

  const [containerHeight, setContainerHeight] = useState<number>(400); // Default height

  // Function to update the container height based on window size
  const updateHeight = () => {
    const windowHeight = window.innerHeight;
    const newHeight = windowHeight - 250; // Minus the top and bottom banners etc...
    setContainerHeight(newHeight);
  };

  useEffect(() => {
    updateHeight(); // Set initial height
    window.addEventListener('resize', updateHeight);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);
  

  return (
    <>
      <Row className=''>
        <Col className='col-12'>
          <Row className='m-0'>
            <Col className="">
              <Row className='m-0 p-0'><Col className='m-0 p-0'> <span className='txt-17'>User Administration</span></Col></Row>
              <Row className='m-0 p-0'><Col className='m-0 p-0'> <span className='txt-12'>Create, Edit and delete users for your organisation</span></Col></Row>
            </Col>
            <Col className="text-end k-center-content-hor-right-cen">
              <Button className="k-h-30 k-btn-submit-col txt-14 k-center-content" onClick={handleShowAddUserModal} type="submit">Add User</Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col className="col-3 p-0 ">
          <Row className="m-0 mt-2">
            <Col className="col-12">
              <div className="">
                <Row className='m-0 ps-2'>
                  <Table className='k-brd-transparent'>
                    <thead className=''>
                      <tr>
                        <th className='pb-1 ps-1 txt-12 color-lightText'>Users</th>
                      </tr>
                    </thead>
                    <div className="k-overflow-y-scroll" style={{ height: `${containerHeight}px` }}>
                      <tbody className='k-t-body-scroll-admin k-disp-inline-tbl k-wh-100pc'>
                        {users &&
                          [...users] // Create a shallow copy to avoid mutating the original array
                            .sort((a: any, b: any) => {
                              const nameA = a?.name?.first?.toLowerCase() || '';
                              const nameB = b?.name?.first?.toLowerCase() || '';
                              return nameA.localeCompare(nameB); // Compare strings alphabetically
                            })
                            .map((user: any, index: number) => {
                              return (
                                <tr className='k-bord-col-trans' key={`admin-users-${user.id}`}>
                                  <td className='pt-1 pb-1' onClick={(event) => handleAdminSelectObject(event, { type: 'USER', id: user.id })}>
                                    <div
                                      className={(selectedUser?.content?.id === user.id) ? 'k-bg-selected-light b-rad-5 p-2' : 'k-hover p-2 b-rad-5'}
                                    >
                                      <Row><Col className='col-12'>{user?.name?.first || ''}</Col></Row>
                                      <Row><Col className='col-12 txt-12 color-lightText'>{user.email}</Col></Row>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                      </tbody>
                    </div>
                  </Table>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>

        <Col className="col-9 p-0">
          <Row className="m-0 mt-2">
            <Col className="col-12">
              {(selectedUser && selectedUser.type === 'USER') ? (
                <>
                  <div className="minh-400">
                    <Row className='m-0 k-pt-8'>
                      <Col className='col-12'>
                        <span className='txt-12 color-lightHeader'>User Profile</span>
                        <hr className='k-border-top-black mt-1 mb-1'></hr>
                      </Col>
                    </Row>
                    <AdminUsersContentEditFormPane
                      userContent={selectedUser.content}
                      groups={groups}
                      editUserSubmitError={editUserSubmitError}
                      handleUserEditSubmit={handleUserEditSubmit}
                      mediaControlHandlers={mediaControlHandlers}
                    />
                  </div> 
                </>
              )
                :
                (<>
                  <div className="minh-400">
                    <Row className='m-0 pt-2'>
                      <Col className='col-12'>
                        <span className='txt-18'></span>
                      </Col>
                    </Row>
                  </div>
                </>
                )}
            </Col>
          </Row>
        </Col>

      </Row>
    </>

  );
}

export const AdminGroupsContentTab: React.FunctionComponent<any> = (props:any) => {
  const { 
    groups,
    users, 
    selectedGroup, 
    mediaControlHandlers,
    editGroupSubmitError,
    handleGroupEditSubmit,
    handleShowAddGroupModal, 
    handleAdminSelectObject 
  } = props;

  const [containerHeight, setContainerHeight] = useState<number>(400); // Default height

  // Function to update the container height based on window size
  const updateHeight = () => {
    const windowHeight = window.innerHeight;
    const newHeight = windowHeight - 250; // Minus the top and bottom banners etc...
    setContainerHeight(newHeight);
  };

  useEffect(() => {
    updateHeight(); // Set initial height
    window.addEventListener('resize', updateHeight);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);


  return (
    <>
      <Row className=''>
        <Col className='col-12'>
          <Row className='m-0'>
            <Col className="">
              <Row className='m-0 p-0'><Col className='m-0 p-0'> <span className='txt-17'>Group Administration</span></Col></Row>
              <Row className='m-0 p-0'><Col className='m-0 p-0'> <span className='txt-12'>Create, Edit and delete user groups for your organisation</span></Col></Row>
            </Col>
            <Col className="text-end k-center-content-hor-right-cen">
              <Button className="k-h-30 k-btn-submit-col txt-14 k-center-content" onClick={handleShowAddGroupModal} type="submit">Add Group</Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row >
        <Col className="col-3 p-0 ">
          <Row className="m-0 mt-2">
            <Col className="col-12">
              <div className="">
                <Row className='m-0 ps-2'>
                  <Table className='k-brd-transparent'>
                    <thead>
                      <tr>
                        <th className='pb-1 ps-1 txt-12 color-lightText'>Groups</th>
                      </tr>
                    </thead>
                    <div className="k-overflow-y-scroll" style={{ height: `${containerHeight}px` }}>
                      <tbody className='k-t-body-scroll-admin k-disp-inline-tbl k-wh-100pc'>
                        {groups &&
                          [...groups] // Create a shallow copy to avoid mutating the original array
                            .sort((a: any, b: any) => {
                              const nameA = a?.name?.toLowerCase() || '';
                              const nameB = b?.name?.toLowerCase() || '';
                              return nameA.localeCompare(nameB); // Compare strings alphabetically
                            })
                            .map((group: any, index: number) => {
                              return (
                                <tr className='k-bord-col-trans' key={`admin-users-${group.id}`}>
                                  <td
                                    className='pt-1 pb-1'
                                    onClick={(event) => handleAdminSelectObject(event, { type: 'GROUP', id: group.id })}
                                  >
                                    <div
                                      className={(selectedGroup?.content?.id === group.id) ? 'k-bg-selected-light b-rad-5 p-2' : 'k-hover p-2 b-rad-5 k-wh-100pc'}
                                    >
                                      <Row><Col className='col-12'>{group.name}</Col></Row>
                                      <Row><Col className='col-12 txt-12 color-lightText'>{group.description}</Col></Row>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                      </tbody>
                    </div>
                  </Table>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>

        <Col className="col-9 p-0">
          <Row className="m-0 mt-2">
            <Col className="col-12">
              {(selectedGroup && selectedGroup.type === 'GROUP') ? (
                <>
                  <div className="minh-400">
                    <Row className='m-0 k-pt-8'>
                      <Col className='col-12'>
                        <span className='txt-12 color-lightHeader'>Group Detail</span>
                        <hr className='k-border-top-black mt-1 mb-1'></hr>
                      </Col>
                    </Row>
                    <AdminGroupsContentEditFormPane
                      groupContent={selectedGroup.content}
                      users={users}
                      editGroupSubmitError={editGroupSubmitError}
                      handleGroupEditSubmit={handleGroupEditSubmit}
                      mediaControlHandlers={mediaControlHandlers}
                    />
                  </div> 
                </>
              )
                :
                (<>
                  <div className="minh-400">
                    <Row className='m-0 pt-2'>
                      <Col className='col-12'>
                        <span className='txt-18'></span>
                      </Col>
                    </Row>
                  </div>
                </>
                )}
            </Col>
          </Row>
        </Col>

      </Row>
    </>

  );
}

export const AdminProductContentTab: React.FunctionComponent<any> = (props:any) => {
  const { 
    users,
    groups,
    products,
    selectedProduct,
    handleAdminSelectObject,
    mediaControlHandlers,
    getProductsCallback
  } = props;

  const [containerHeight, setContainerHeight] = useState<number>(250); // Default height
  
  useEffect(() => {
    helpers.logToOutput('AdminProductContentTab selectedProduct has changed: ', selectedProduct);
  },[selectedProduct]);


  const getUserNameById = (id: string): string | null => {
    const user = users.find((user: any) => user.id === id);
  
    if (user) {
      // If display_name is provided, return it, otherwise construct the name from first, middle, and last.
      return user.display_name || `${user.name.first} ${user.name.middle} ${user.name.last}`.trim();
    }
    
    return null; // Return null if no user is found
  };

  // Function to update the container height based on window size
  const updateHeight = () => {
    const windowHeight = window.innerHeight;
    const newHeight = windowHeight - 250; // Minus the top and bottom banners etc...
    setContainerHeight(newHeight);
  };

  useEffect(() => {
    updateHeight(); // Set initial height
    window.addEventListener('resize', updateHeight);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);



  return (
    <>
      <Row className=''>
        <Col className='col-12'>
          <Row className='m-0'>
            <Col className="">
              <Row className='m-0 p-0'><Col className='m-0 p-0'> <span className='txt-17'>Product Administration</span></Col></Row>
              <Row className='m-0 p-0'><Col className='m-0 p-0'> <span className='txt-12'>Administer desktops and servers for your organisation</span></Col></Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className='mt-2'>
        <Col className="col-3 p-0 ">
          <Row className="m-0">
            <Col className="col-12">
              <div className="">
                <Row className='m-0 ps-2'>
                  <Table className='k-brd-transparent'>
                    <thead className=''>
                      <tr>
                        <th className='pb-1 ps-1 txt-12 color-lightText'>Desktops</th>
                      </tr>
                    </thead>
                    <div className="k-overflow-y-scroll" style={{ height: `${containerHeight}px` }}>
                      <tbody className='k-t-body-scroll-admin k-disp-inline-tbl k-wh-100pc'>
                        {products && products.map((product: any, index: number) => {
                          return (
                            <tr className='k-bord-col-trans' key={`admin-users-${product?.id ?? index}`}>
                              <td className='pt-1 pb-1' onClick={(event) => handleAdminSelectObject(event, { type: 'PRODUCT', id: product?.id ?? 'None' })}>
                                <div className={(selectedProduct?.content?.id === product?.id) ? 'k-bg-selected-light b-rad-5 p-2' : 'k-hover p-2 b-rad-5 k-wh-100pc'}>
                                  <Row>
                                    <Col className='col-2'>
                                      <img className='k-mh-30 mt-1' id={product?.parameters?.System ?? 'None'} src={imageMap[product?.parameters?.System] ?? ''} alt='Product Image' />
                                    </Col>
                                    <Col className='col-10'>
                                      <Row><Col className='col-12'>{product?.tags?.kname ?? 'None'}</Col></Row>
                                      <Row>
                                        <Col className='col-12 txt-12 color-lightText'>
                                          {getUserNameById(product?.user) ?? 'Unknown User'} / {(product?.provider ?? 'None').toUpperCase()} / {variables?.regionToLocationMap?.[product?.region] ?? 'None'}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </div>
                  </Table>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>

        <Col className="col-9 p-0">
          <Row className="m-0 mt-2">
            <Col className="col-12">
              {(selectedProduct && selectedProduct.type === 'PRODUCT') ? (
                <>
                  <Tab.Container id="profile-content-pane" defaultActiveKey="admin.ProductGeneral">
                    <Row id="k-adm-dash-l5" className='m-0 p-0 mt-1'>
                      <Nav className="pb-1">
                        <Nav.Item className="">
                          <Nav.Link eventKey="admin.ProductGeneral" className="p-0 txt-12">
                            General
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="ms-3">
                          <Nav.Link eventKey="admin.ProductStorage"  disabled className="p-0 txt-12 color-lightText">
                            Storage
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="ms-3">
                          <Nav.Link eventKey="admin.ProductMonitor"  disabled className="p-0 txt-12 color-lightText">
                            Monitor
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="ms-3">
                          <Nav.Link eventKey="admin.ProductLogs" disabled className="p-0 txt-12 color-lightText">
                            Logs  
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Row>
                    <hr id="k-adm-dash-l6" className='k-border-top-black mt-0 mb-0'></hr>
                    <Row className="mt-0 pe-0 m-0 ">
                      <Tab.Content className='mt-0 pt-1 k-fg-1 k-of-y-auto'>
                        <Tab.Pane eventKey="admin.ProductGeneral">
                          <div className="">
                            <AdminProductContentFormPane
                              productContent={selectedProduct}
                              getUserNameById={getUserNameById}
                              getProductsCallback={getProductsCallback}
                            />
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Row>
                  </Tab.Container>
                </>
              )
                :
                (<>
                  <div className="minh-400">
                    <Row className='m-0 pt-2'>
                      <Col className='col-12'>
                        <span className='txt-18'></span>
                      </Col>
                    </Row>
                  </div>
                </>
                )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export const AdminNumberPlatformContentTab: React.FunctionComponent<any> = (props:any) => {
  const { 
    numbers,
    providers,
    messages,
    providerNumbers,
    getProviders,
    getNumbers,
    getMessages,
    isProviderRefreshing,
    getProviderDetails,
    handleAdminSelectObject,
    handleProviderRefresh,
    selectedObject,
  } = props;

  const [containerHeight, setContainerHeight] = useState<number>(250); // Default height
  const [numberStateSelected, setNumberStateSelected] = useState<Array<{ label: string, value: string }>>([]);
  const [options, setOptions] = useState<{ label: string; value: string }[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [isNumberLoading, setNumberLoading] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const [messageFilter, setMessageFilter] = useState(''); // State to track the filter value
  const [numberFilter, setNumberFilter] = useState(''); // State to track the filter value

  const [listItems, setListItems] = useState([]); // Now stores data objects
  const [loadingMessage, setLoadingMessage] = useState(''); // For loading messages
  
  const [apiKey, setApiKey] = useState('');
  const [apiDomain, setApiDomain] = useState('');
  const [isMasked, setIsMasked] = useState(true); // State to toggle masking

  const [showModal, setShowModal] = useState(false); // Modal visibility
  const [confirmationInput, setConfirmationInput] = useState(''); // Input in modal

  const prevIsProviderRefreshingRef = useRef();

  const numberStateOptions = [{value: 'available', label: 'Activate'}, {value: 'suspended', label: 'Suspend'}, {value: 'release', label: 'Release'}]

  const toggleMasking = () => setIsMasked(!isMasked);
  
  const handleControlClick = (id:any) => {
    helpers.logToOutput('AdminNumberPlatformContentTab handleControlClick Number Control: ', id);
  };

  // Function to update the container height based on window size
  const updateHeight = () => {
    const windowHeight = window.innerHeight;
    const newHeight = windowHeight - 290; // Minus the top and bottom banners etc...
    setContainerHeight(newHeight);
  };

  useEffect(() => {
    updateHeight(); // Set initial height
    window.addEventListener('resize', updateHeight);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  useEffect(() => {

    const fetchProviderDetails = async () => {
      const currentSelectedObject = selectedObject;
      if (currentSelectedObject?.content?.id) {
        const pDetails = await getProviderDetails(currentSelectedObject.content.id);

        if (pDetails != null) {
          const parsedApiData = JSON.parse(pDetails.response) 
          setApiKey(parsedApiData['key'] || '');
          setApiDomain(parsedApiData['domain'] || '');
        }
        else {
          setApiKey('');
          setApiDomain('');
        }
      }
    };

    if (selectedObject && selectedObject.type == 'PROVIDER') {
      fetchProviderDetails();
    }
    
  
  }, [selectedObject]);

  
  useEffect(() => {
    const prevIsProviderRefreshing = prevIsProviderRefreshingRef.current;
  
    // Detect when refresh starts
    if (!prevIsProviderRefreshing && isProviderRefreshing) {
      // Clear the list when refresh starts
      setListItems([]);
    }
  
    prevIsProviderRefreshingRef.current = isProviderRefreshing;
  
    if (isProviderRefreshing) {
      // Set loading message
      setLoadingMessage('Connecting to provider, please wait...');
    } else {
      // Clear loading message
      setLoadingMessage('Connected, the following numbers have been identified');
      if (providerNumbers && providerNumbers.length > 0) {
        // Set listItems to providerNumbers directly
        setListItems(providerNumbers);
      }
      // No else block: We don't clear the list when there's no data
    }
  }, [providerNumbers, isProviderRefreshing]);


  const valueRenderer = (numberStateSelected: typeof options) => {

    if (!numberStateSelected.length) {
      return <></>;
    }

    return numberStateSelected.length === 1 ? (
      <span className='k-bg-selected m-1 p-1 b-rad-5'>{numberStateSelected[0].label}</span>
    ) : (
      numberStateSelected.map(({ label }) => <span className='k-bg-selected m-1 p-1 b-rad-5' key={label}>{label}</span>)
    );

    
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    
    
    if (selectedObject?.content?.id && selectedObject?.content?.name) {
      updateProviderDetails(selectedObject.content.id, apiKey, apiDomain, selectedObject.content.name);
    }
    else {
      helpers.logToOutput('Provider ID not set: ');
    }
  };

  const updateProviderDetails = async (id: string, key: string, domain: string, displayName: string) => {
    setLoading(true);
    
    toast.info('Please wait while we update the provider details')
    const putResponse = await api.adminPutProvider({
      id: id,
      body: {
        name: 'general',
        key: key,
        domain: domain,
        display_name: displayName
      },
    });

  
    if (putResponse?.status === 200) {
      
      toast.success('Provider details updated');
      setLoading(false);

    } else {
      
      toast.warn('Sorry something has gone wrong, please try again or contact the support desk')
      setLoading(false);
    }
  };

  const updateNumberDetails = async (id: string, state: string, providerID: string) => {
    setNumberLoading(true);
    
    toast.info('Please wait while we update the number details')
    const putResponse = await api.adminPutNumber({
      id: id,
      body: {
        id: id,
        status: state,
      },
    });

    if (putResponse?.status === 200) {
      
      handleProviderRefresh(providerID)
      getNumbers()
      toast.success('Number details updated');
      setNumberLoading(false);

    } else {
      
      toast.warn('Sorry something has gone wrong, please try again or contact the support desk')
      getNumbers()
      setNumberLoading(false);
    }
  };

  const clearForm = () => {
    setApiKey('');
    setApiDomain('');
    setListItems(null);
  }

  const handleConfirmRelease = (input: string) => {
    if (input === selectedObject?.content?.number) {
      updateNumberDetails(selectedObject.content?.id, selectedState, selectedObject.content?.provider);
      setShowModal(false); // Close modal
    } else {
      toast.warn('The entered number does not match. Please try again.');
    }
  };

  return (
    <>
      <Row className=''>
        <Col className='col-12'>
          <Row className='m-0'>
            <Col className="">
              <Row className='m-0 p-0'><Col className='m-0 p-0'> <span className='txt-17'>Number Administration</span></Col></Row>
              <Row className='m-0 p-0'><Col className='m-0 p-0'> <span className='txt-12'>Administer numbers your organisation</span></Col></Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className='mt-2'>
        <Col className="col-12">
          <Row className="m-0 mt-2">
            <Col className="col-12">
              <Tab.Container id="profile-content-pane" defaultActiveKey="admin.NumberProvider">
                <Row id="k-adm-dash-l5" className='m-0 p-0 mt-1'>
                  <Nav className="pb-1">
                    <Nav.Item className="">
                      <Nav.Link eventKey="admin.NumberProvider" className="p-0 txt-12">
                        Provider
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="ms-3">
                      <Nav.Link eventKey="admin.NumberGeneral" className="p-0 txt-12">
                        Numbers
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="ms-3">
                      <Nav.Link eventKey="admin.NumberSMS" className="p-0 txt-12">
                        SMS
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="ms-3">
                      <Nav.Link eventKey="admin.NumberLogs" disabled className="p-0 txt-12 color-lightText">
                        Logs  
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Row>
                <hr id="k-adm-dash-l6" className='k-border-top-black mt-0 mb-0'></hr>
                <Row className="mt-0 pe-0 m-0 ">
                  <Tab.Content className='mt-0 pt-1 k-fg-1 k-of-y-auto'>
                    <Tab.Pane eventKey="admin.NumberGeneral">
                      <div className="">
                        <Row>
                          <Col className="col-3 p-0 ">
                            <Row className="m-0 mt-2">
                              <Col className="col-12">
                                <div className="mb-2">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search by number..."
                                    value={numberFilter}
                                    onChange={(e) => setNumberFilter(e.target.value)}
                                  />
                                </div>
                                <div className="k-overflow-y-scroll" style={{ height: `${containerHeight}px` }}>
                                  <tbody className='k-t-body-scroll-admin k-disp-inline-tbl k-wh-100pc'>
                                    {numbers
                                      ?.filter((number: any) =>
                                        number?.number?.toLowerCase().includes(numberFilter.toLowerCase())
                                      )
                                      .map((number: any, index: number) => {
                                        return (
                                          <tr className='k-bord-col-trans' key={`admin-numbers-${number?.id ?? index}`}>
                                            <td className='pt-1 pb-1' onClick={(event) => handleAdminSelectObject(event, { type: 'NUMBER', id: number?.id ?? 'None' })}>
                                              <div className={(selectedObject?.content?.id === number?.id) ? 'k-bg-selected-light b-rad-5 p-2' : 'k-hover p-2 b-rad-5 k-wh-100pc'}>
                                                <Row>
                                                  <Col className='col-2'>
                                                    <img className='k-mh-30 mt-1' id={number?.provider ?? 'None'} src={imageMap[number?.provider] ?? ''} alt='Product Image' />
                                                  </Col>
                                                  <Col className='col-10'>
                                                    <Row><Col className='col-12'>{number?.number ?? 'None'} <span className='txt-10 color-lightText'>{number?.provider_name ?? 'Unknown Provider'} </span></Col></Row>
                                                    <Row>
                                                      <Col className='col-12 txt-12 color-lightText'>
                                                        {(number?.status ?? 'Unknown').toUpperCase()} / {(number?.country ?? 'None').toUpperCase()}
                                                      </Col>
                                                    </Row>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col className='col-9 p-0'>
                            <Row className="m-0 mt-2">
                              <Col className="col-12">
                                {(selectedObject && selectedObject.type === 'NUMBER') ? (
                                  <>
                                    <div className="minh-400">
                                      <Row className='m-0 k-pt-3'>
                                        <Col className='col-12'>
                                          <Card className='k-b-shadow-none ps-0 growable-div k-overflow-a'>
                                            <Card.Header className='ps-3'>
                                              <Row>
                                                <Col className='col-6'>
                                                  <Row>
                                                    <Col className='col-12'>
                                                      <h4 className='mt-0 mb-0'>{selectedObject?.content?.number ?? 'None'}</h4>
                                                    </Col>
                                                  </Row>
                                                  <Row>
                                                    <Col className='col-12 txt-12 color-lightText'>
                                                      {(selectedObject?.content?.status ?? 'None').toUpperCase()} / {(selectedObject.content?.countryCode ?? 'None').toUpperCase()} {(selectedObject.content?.country ?? 'None').toUpperCase()}
                                                    </Col>
                                                  </Row>
                                                </Col>
                                                <Col className=''>  
                                                  <Row>
                                                    <Col className="col-9">
                                                      <Select
                                                        options={numberStateOptions}
                                                        isMulti={false}
                                                        onChange={(selectedOption) => setSelectedState(selectedOption?.value)} // Update selectedState
                                                      />
                                                    </Col>
                                                    <Col className="col-3 d-flex justify-content-center align-items-center">
                                                      <Button
                                                        className="k-min-w-103 p-2"
                                                        variant="outline-dark"
                                                        disabled={isNumberLoading || !selectedState} // Disable if loading or no selection
                                                        onClick={() => {
                                                          if (selectedState === 'release') {
                                                            setShowModal(true); // Show confirmation modal for "Release"
                                                          } else {
                                                            updateNumberDetails(selectedObject.content?.id, selectedState, selectedObject.content?.provider);
                                                          }
                                                        }}
                                                      >
                                                        {isNumberLoading ? 'Updating...' : 'Update'}
                                                      </Button>
                                                    </Col>
                                                  </Row>
                                                </Col>
                                              </Row>
                                            </Card.Header>
                                            <Card.Body className='p-0 pt-4'>
                                              <Row>
                                                <Col md={6}>
                                                  <Card>
                                                    <Card.Header className='p-2 ps-3 txt-14' ><strong>General</strong></Card.Header>
                                                    <ListGroup variant="flush txt-12">
                                                      <ListGroup.Item>
                                                        <Row>
                                                          <Col className='col-3'><strong>Provider:</strong></Col>
                                                          <Col className='col-9'>{(selectedObject.content?.provider_name ?? 'None').toUpperCase()}</Col>
                                                        </Row>
                                                      </ListGroup.Item>
                                                      <ListGroup.Item>
                                                        <Row>
                                                          <Col className='col-3'><strong>ID:</strong> </Col>
                                                          <Col className='col-9 pe-0'>{(selectedObject.content?.id ?? 'None').toUpperCase()}</Col>
                                                        </Row>
                                                      </ListGroup.Item>
                                                      <ListGroup.Item>
                                                        <Row>
                                                          <Col className='col-3'><strong>Attached Device:</strong> </Col>
                                                          <Col className='col-9 pe-0'>{(selectedObject.content?.device_name ?? 'None').toUpperCase()}</Col>
                                                        </Row>
                                                      </ListGroup.Item>
                                                    </ListGroup>
                                                  </Card>
                                                </Col>

                                                <Col md={6}>
                                                  <Card>
                                                    <Card.Header className='p-2 ps-3 txt-14'><strong>Capabilities</strong></Card.Header>
                                                    <ListGroup variant="flush txt-12">
                                                      <ListGroup.Item>
                                                        <Row>
                                                          <Col className='col-auto'><strong>SMS</strong></Col>
                                                          <Col className='col-auto'>{selectedObject.content?.sms ? 'True' : 'False'}</Col>
                                                        </Row>
                                                      </ListGroup.Item>
                                                      <ListGroup.Item>
                                                        <Row>
                                                          <Col className='col-auto'><strong>Voice</strong></Col>
                                                          <Col className='col-auto'>{selectedObject.content?.voice ? 'True' : 'False'}</Col>
                                                        </Row>
                                                      </ListGroup.Item>
                                                    </ListGroup>
                                                  </Card>
                                                </Col>
                                              </Row>

                                              <Row className="mt-3">
                                                <Col md={12}>
                                                  <Card>
                                                    <Card.Header className='p-2 ps-3 txt-14'><strong>Usage</strong></Card.Header>
                                                    <ListGroup variant="flush txt-12">
                                                      {/*content?.share?.SS ? content.share.SS.map((ss:any, index:any) => (
                                                        <ListGroup.Item key={index}>
                                                          <strong>User {index + 1}:</strong> {getUserNameById(ss) ?? 'Unknown User'}
                                                        </ListGroup.Item>
                                                      )) : <ListGroup.Item>Product not shared</ListGroup.Item>*/}
                                                    </ListGroup>
                                                  </Card>
                                                </Col>
                                              </Row>
                                            </Card.Body>
                                          </Card>
                                        </Col>
                                      </Row>
                                    </div> 
                                  </>
                                )
                                  :
                                  (<>
                                    <div className="minh-400">
                                      <Row className='m-0 pt-2'>
                                        <Col className='col-12'>
                                          <span className='txt-18'></span>
                                        </Col>
                                      </Row>
                                    </div>
                                  </>
                                  )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="admin.NumberProvider">
                      <div className="">
                        <Row>
                          <Col className="col-3 p-0 ">
                            <Row className="m-0 mt-2">
                              <Col className="col-12">
                                <div className="k-overflow-y-scroll" style={{ height: `${containerHeight}px` }}>
                                  <tbody className='k-t-body-scroll-admin k-disp-inline-tbl k-wh-100pc'>
                                    {providers && providers.map((provider: any, index: number) => {
                                      return (
                                        <tr className='k-bord-col-trans' key={`admin-providers-${provider.id}`}>
                                          <td className='pt-1 pb-1' onClick={(event)=> {clearForm(); handleAdminSelectObject(event, {type: 'PROVIDER', id: provider.id})}} >
                                            <div className={(selectedObject?.content?.id === provider.id) ? 'k-bg-selected-light b-rad-5 p-2' : 'k-hover p-2 b-rad-5'}>
                                              <Row>
                                                <Col xs="auto">
                                                  <img className='k-mh-30 mt-1' id={provider?.name ?? 'None'} src={imageMap[provider?.name] ?? ''} alt='Product Image' />
                                                </Col>
                                                <Col>
                                                  <Row><Col className='col-12'>{provider?.name || ''}</Col></Row>
                                                  <Row><Col className='col-12 txt-12 color-lightText'>{(provider?.state ?? 'None').toUpperCase()}</Col></Row>
                                                </Col>
                                              </Row>
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col className='col-9 p-0'>
                            <Row className="m-0 mt-2">
                              <Col className="col-12">
                                {(selectedObject && selectedObject.type === 'PROVIDER') ? (
                                  <>
                                    <div className="minh-400">
                                      <Row className='m-0 k-pt-3'>
                                        <Col className='col-12'>
                                          <Card className='k-b-shadow-none ps-0 growable-div k-overflow-a'>
                                            <Card.Header className='ps-3'>
                                              <Row>
                                                <Col className='col-6'>
                                                  <Row>
                                                    <Col className='col-12'>
                                                      <h4 className='mt-0 mb-0'>{selectedObject?.content?.name ?? 'None'}</h4>
                                                    </Col>
                                                  </Row>
                                                  <Row>
                                                    <Col className='col-12 txt-12 color-lightText'>
                                                      {(selectedObject?.content?.state ?? 'None').toUpperCase()}
                                                    </Col>
                                                  </Row>
                                                </Col>
                                                <Col className=''>  
                                                  <Row className='d-flex d-flex justify-content-end align-items-center'>
                                                    <Col className='col-3 d-flex justify-content-end align-items-center'>
                                                      <Button className="k-min-w-103 p-2" variant="outline-dark" type="submit" form="apiForm" disabled={isLoading || selectedObject?.content?.state !== 'active'}>
                                                        {isLoading ? 'updating...' : 'update'}
                                                      </Button>
                                                    </Col>
                                                  </Row>
                                                </Col>
                                              </Row>
                                            </Card.Header>
                                            <Card.Body className='p-0 pt-4'>
                                              <Row>
                                                <Col md={12}>
                                                  <Form id="apiForm" onSubmit={handleSubmit}>
                                                    <Row>
                                                      <Col md={12}>
                                                        <Form.Group controlId="apiKey" className="mb-3 px-2">
                                                          <Form.Label>API Key</Form.Label>
                                                          <InputGroup className="">
                                                            <Form.Control
                                                              type={isMasked ? 'password' : 'text'} 
                                                              placeholder="Enter API Key"
                                                              value={apiKey}
                                                              onChange={(e) => setApiKey(e.target.value)}
                                                              required
                                                              disabled={isLoading || selectedObject?.content?.state !== 'active'}
                                                            />
                                                            <InputGroup.Text id="apikeyinput" onClick={toggleMasking} style={{ cursor: 'pointer' }}>
                                                              {isMasked ? (
                                                                <FontAwesomeIcon icon={faEyeSlash} />
                                                              ) : (
                                                                <FontAwesomeIcon icon={faEye} />
                                                              )}
                                                            </InputGroup.Text>
                                                          </InputGroup>
                                                        </Form.Group>
                                                      </Col>
                                                      <Col md={12}>
                                                        <Form.Group controlId="apiDomain" className="mb-3 px-2">
                                                          <Form.Label>API Domain</Form.Label>
                                                          <InputGroup className="">
                                                            <InputGroup.Text>https://</InputGroup.Text>
                                                            <Form.Control
                                                              type="text"
                                                              placeholder="Enter API Domain"
                                                              value={apiDomain}
                                                              onChange={(e) => setApiDomain(e.target.value)}
                                                              required
                                                              disabled={isLoading || selectedObject?.content?.state !== 'active'}
                                                            />
                                                          </InputGroup>
                                                        </Form.Group>
                                                      </Col>
                                                    </Row>
                                                  </Form>
                                                </Col>
                                              </Row>

                                              <Row className="mt-3">
                                                <Col md={12}>
                                                  <Card>
                                                    <Card.Header className='ps-3 py-2'>
                                                      <Row>
                                                        <Col className='col-6'>
                                                          <h5 className='mt-0 mb-0'>Numbers</h5>
                                                        </Col>
                                                        <Col className=''>  
                                                          <Row className='d-flex d-flex justify-content-end align-items-center'>
                                                            <Col className='col-3 d-flex justify-content-end align-items-center'>
                                                              <Button className="k-min-w-103 p-0" variant="outline-dark" onClick={() => handleProviderRefresh(selectedObject?.content?.id)} disabled={isProviderRefreshing || selectedObject?.content?.state !== 'active'}>
                                                                {isProviderRefreshing ? 'refreshing...' : 'refresh'}
                                                              </Button>
                                                            </Col>
                                                          </Row>
                                                        </Col>
                                                      </Row>
                                                    </Card.Header>
                                                    <Card.Body className='p-0 pt-4 px-2'>
                                                      {loadingMessage && <div>{loadingMessage}</div>}
                                                      <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                                        <Table striped bordered hover size="sm">
                                                          <thead>
                                                            <tr>
                                                              <th className='p-1'>Number</th>
                                                              <th className='p-1'>Country</th>
                                                              <th className='p-1'>Capabilities</th>
                                                              {/* Add more columns as needed */}
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            {listItems &&
                                                              <>
                                                                {listItems.map((item, index) => (
                                                                  <tr key={index}>
                                                                    <td className='p-1'>{item.number}</td>
                                                                    <td className='p-1'>{item.countryName}</td>
                                                                    <td className='p-1'>{item.capabilities.join(', ')}</td>
                                                                  </tr>
                                                                ))}
                                                              </>
                                                            }
                                                          </tbody>
                                                        </Table>
                                                      </div>
                                                    </Card.Body>
                                                  </Card>
                                                </Col>
                                              </Row>
                                            </Card.Body>
                                          </Card>
                                        </Col>
                                      </Row>
                                    </div> 
                                  </>
                                )
                                  :
                                  (<>
                                    <div className="minh-400">
                                      <Row className='m-0 pt-2'>
                                        <Col className='col-12'>
                                          <span className='txt-18'></span>
                                        </Col>
                                      </Row>
                                    </div>
                                  </>
                                  )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="admin.NumberSMS">
                      <div className="">
                        <Row>
                          <Col className="col-3 p-0 ">
                            <Row className="m-0 mt-2">
                              <Col className="col-12">
                                <div className="mb-2">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search by number..."
                                    value={messageFilter}
                                    onChange={(e) => setMessageFilter(e.target.value)}
                                  />
                                </div>
                                <div className="k-overflow-y-scroll" style={{ height: `${containerHeight}px` }}>
                                  <tbody className='k-t-body-scroll-admin k-disp-inline-tbl k-wh-100pc'>
                                    { messages
                                      ?.filter((message: any) =>
                                        message?.destination?.toLowerCase().includes(messageFilter.toLowerCase())
                                      ) // Apply the filter
                                      .sort((a: any, b: any) => (b.time_epoch ?? 0) - (a.time_epoch ?? 0)) // Sort by time_epoch
                                      .map((message: any, index: number) => {
                                        return (
                                          <tr className='k-bord-col-trans' key={`admin-messages-${message?.message_id ?? index}`}>
                                            <td className='pt-1 pb-1' onClick={(event) => handleAdminSelectObject(event, { type: 'MESSAGE', id: message?.message_id ?? 'None' })}>
                                              <div className={(selectedObject?.content?.message_id === message?.message_id) ? 'k-bg-selected-light b-rad-5 p-2' : 'k-hover p-2 b-rad-5 k-wh-100pc'}>
                                                <Row>
                                                  <Col className='col-2'>
                                                    <img className='k-mh-30 mt-1' id={message?.provider ?? 'None'} src={imageMap['message'] ?? ''} alt='Product Image' />
                                                  </Col>
                                                  <Col className='col-10'>
                                                    <Row className='ms-1'>
                                                      <Col className='col-12'>{message?.destination ?? 'None'}</Col></Row>
                                                    <Row className='ms-1'>
                                                      <Col className='col-12 txt-12 color-lightText'>
                                                        {(helpers.getConditionalDateTime(message?.time_epoch, true, true, false) ?? 'None').toUpperCase()}
                                                      </Col>
                                                    </Row>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      })}
                                  </tbody>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col className='col-9 p-0'>
                            <Row className="m-0 mt-2">
                              <Col className="col-12">
                                {(selectedObject && selectedObject.type === 'MESSAGE') ? (
                                  <>
                                    <div className="minh-400">
                                      <Row className='m-0 k-pt-3'>
                                        <Col className='col-12'>
                                          <Card className='k-b-shadow-none ps-0 growable-div k-overflow-a'>
                                            <Card.Header className='ps-3'>
                                              <Row>
                                                <Col className='col-6'>
                                                  <Row>
                                                    <Col className='col-12'>
                                                      <h4 className='mt-0 mb-0'>{selectedObject?.content?.destination ?? 'None'}</h4>
                                                    </Col>
                                                  </Row>
                                                  <Row>
                                                    <Col className='col-12 txt-12 color-lightText'>
                                                      {selectedObject?.content?.product_id ?? 'Unassociated'}
                                                    </Col>
                                                  </Row>
                                                </Col>
                                              </Row>
                                            </Card.Header>
                                            <Card.Body className='p-0 pt-4'>
                                              <Row>
                                                <Col md={6}>
                                                  <Card>
                                                    <Card.Header className='p-2 ps-3 txt-14 b-rad-t-5' ><strong>General</strong></Card.Header>
                                                    <ListGroup variant="flush txt-12">
                                                      <ListGroup.Item>
                                                        <Row>
                                                          <Col className='col-3'><strong>From:</strong></Col>
                                                          <Col className='col-9'>{(selectedObject.content?.sender ?? 'None')}</Col>
                                                        </Row>
                                                      </ListGroup.Item>
                                                      <ListGroup.Item>
                                                        <Row>
                                                          <Col className='col-3'><strong>To:</strong> </Col>
                                                          <Col className='col-9 pe-0'>{(selectedObject.content?.destination ?? 'None')}</Col>
                                                        </Row>
                                                      </ListGroup.Item>
                                                    </ListGroup>
                                                  </Card>
                                                </Col>

                                                <Col md={6}>
                                                  <Card>
                                                    <Card.Header className='p-2 ps-3 txt-14 b-rad-t-5'><strong>Status</strong></Card.Header>
                                                    <ListGroup variant="flush txt-12">
                                                      <ListGroup.Item>
                                                        <Row>
                                                          <Col className='col-3'><strong>Delivered:</strong> </Col>
                                                          <Col className='col-9 pe-0'>{selectedObject.content?.delivered === undefined ? 'None' : selectedObject.content.delivered === 0 ? 'False' : 'True'}</Col>
                                                        </Row>
                                                      </ListGroup.Item>
                                                      <ListGroup.Item>
                                                        <Row>
                                                          <Col className='col-auto'><strong>Received Time</strong></Col>
                                                          <Col className='col-auto'>{(helpers.getConditionalDateTime(selectedObject?.content?.time_epoch, true, true, false) ?? 'None').toUpperCase()}</Col>
                                                        </Row>
                                                      </ListGroup.Item>
                                                    </ListGroup>
                                                  </Card>
                                                </Col>
                                              </Row>

                                              <Row className="mt-3">
                                                <Col md={12}>
                                                  <Card>
                                                    <Card.Header className='p-2 ps-3 txt-14 b-rad-t-5'>
                                                      <Row>
                                                        <Col className='col-12'>
                                                          <Row>
                                                            <Col className='col-12'>
                                                              <strong>Message </strong>
                                                            </Col>
                                                          </Row>
                                                          <Row>
                                                            <Col className='col-12 txt-12 color-lightText'>
                                                              ID:{selectedObject.content?.message_id ?? 'None'}
                                                            </Col>
                                                          </Row>
                                                        </Col>
                                                      </Row>
                                                    </Card.Header>
                                                    <Card.Body className='k-content-cornsilk-bg b-rad-b-5'>
                                                      {selectedObject.content?.clean_text ?? 'None'}
                                                    </Card.Body>
                                                  </Card>
                                                </Col>
                                              </Row>
                                            </Card.Body>
                                          </Card>
                                        </Col>
                                      </Row>
                                    </div> 
                                  </>
                                )
                                  :
                                  (<>
                                    <div className="minh-400">
                                      <Row className='m-0 pt-2'>
                                        <Col className='col-12'>
                                          <span className='txt-18'></span>
                                        </Col>
                                      </Row>
                                    </div>
                                  </>
                                  )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Row>
              </Tab.Container>
            </Col>
          </Row>
        </Col>
      </Row>

      <ConfirmReleaseModal
        show={showModal}
        number={selectedObject?.content?.number || ''}
        onClose={() => setShowModal(false)}
        onConfirm={handleConfirmRelease}
      />
    </>
  );
}

const AdminProductContentFormPane: React.FunctionComponent<{ productContent: types.ProductContent, getUserNameById: (id: string) => string | null, getProductsCallback: any }> = 
({ productContent, getUserNameById, getProductsCallback }) => {

  
  const authContext = useContext(authContextHelper.AuthContext);
  const [controlled, setControlled] = useState(true);
  const [ownership, setOwnership] = useState(false);
  const [content, setContent] = useState<any>({});

  useEffect(() => {
    //Get Header height
    const header = document.querySelector('#k-app-header') as HTMLElement | null;
    const headerHeight = header ? header.offsetHeight : 0;
    
  
    const layerOne = document.querySelector('#k-adm-dash-l1') as HTMLElement | null;
    const layerOneHeight = layerOne ? layerOne.offsetHeight : 0;
    
  
    const layerTwo = document.querySelector('#k-adm-dash-l2') as HTMLElement | null;
    const layerTwoHeight = layerTwo ? layerTwo.offsetHeight : 0;
    
  
    const layerThree = document.querySelector('#k-adm-dash-l3') as HTMLElement | null;
    const layerThreeHeight = layerThree ? layerThree.offsetHeight : 0;
    
  
    const layerFour = document.querySelector('#k-adm-dash-l4') as HTMLElement | null;
    const layerFourHeight = layerFour ? layerFour.offsetHeight : 0;
    
  
    const layerFive = document.querySelector('#k-adm-dash-l5') as HTMLElement | null;
    const layerFiveHeight = layerFive ? layerFive.offsetHeight : 0;
    
  
    const layerSix = document.querySelector('#k-adm-dash-l6') as HTMLElement | null;
    const layerSixHeight = layerSix ? layerSix.offsetHeight : 0;
    
  
    const footerLayer = document.querySelector('#k-app-footer-le') as HTMLElement | null;
    const footerLayerHeight = footerLayer ? footerLayer.offsetHeight : 0;
    

    const hackHeight = 100;
  
    const totalHeight =
      headerHeight +
      layerOneHeight +
      layerTwoHeight +
      layerThreeHeight +
      layerFourHeight +
      layerFiveHeight +
      layerSixHeight +
      hackHeight;
  
    document.documentElement.style.setProperty('--remaining-height', `calc(100vh - ${totalHeight}px - ${footerLayerHeight}px)`);
  }, []);
  
  useEffect(() => {
    
    setContent(productContent.content);
  }, [productContent]);
  
  useEffect(() => {
    if (!content?.id) return; // Check if content.id exists before running the effect
  
    
    
    
  
    if (content?.user && content.user !== authContext.userProfile.attributes.sub) {
      
      setOwnership(false);
  
      if (content?.share?.SS) {
        
        const isMatching = content.share.SS.some((ss: any) => ss === authContext.userProfile.attributes.sub);
        if (isMatching) {
          
          setControlled(false);
        } else {
          
          setControlled(true);
        }
      } else {
        
        setControlled(true);
      }
    } else {
      
      setControlled(false);
      setOwnership(true);
    }
  }, [content.id]);
  

  // Function to handle control with API call
  const handleControlClick = async (newControlStatus: boolean, prod_id: string) => {
    try {
      
      // Prepare the body for the API request (assuming an object structure)
      const query = {
        id: prod_id,
        body: {
          action: !newControlStatus ? 'ADD' : 'DELETE',
          user: authContext.userProfile.attributes.sub
        }
      };

      const response = await api.setAdminControl(query);

      if (response.status === DEFAULT_API_RESULT.SUCCESS) {
        // If API call succeeds, update the state
        
        setControlled(newControlStatus);
      } else {
        // If the response indicates failure, handle it
        
      }
      getProductsCallback()
    } catch (error) {
      // Handle any unexpected errors during the API call
      
      getProductsCallback()
    }
  };

  return (
    <>
      <Card className='k-b-shadow-none ps-0 growable-div k-overflow-a'>
        <Card.Header className='ps-3'>
          <Row>
            <Col className='col-8'>
              <Row>
                <Col className='col-12'>
                  <h4 className='mt-0 mb-0'>{content?.tags?.kname ?? 'None'}</h4>
                </Col>
              </Row>
              <Row>
                <Col className='col-12 txt-12 color-lightText'>
                  {getUserNameById(content?.user) ?? 'Unknown User'} / {(content?.provider ?? 'None').toUpperCase()} / {variables?.regionToLocationMap?.[content?.region] ?? 'None'}
                </Col>
              </Row>
            </Col>
            {!ownership ? 
              <>
                <Col className='k-align-content-center d-flex justify-content-end'>
                  <Button variant="success" type="submit" 
                    disabled={!controlled} size="sm" className="k-btn"
                    onClick={() => handleControlClick(!controlled, content.id)}>
                      Control
                  </Button>
                </Col>
                <Col className='col-auto k-align-content-center d-flex'>
                  <Button variant="warning" type="submit" 
                    disabled={controlled} size="sm" className="k-btn"
                    onClick={() => handleControlClick(!controlled, content.id)}>
                    Release
                  </Button>
                </Col> 
              </> : 
              <></>
            }
          </Row>
        </Card.Header>
        <Card.Body className='p-0 pt-4'>
          <Row>
            <Col md={6}>
              <Card>
                <Card.Header className='p-2 ps-3 txt-14' ><strong>Product Info</strong></Card.Header>
                <ListGroup variant="flush txt-12">
                  <ListGroup.Item>
                    <Row>
                      <Col className='col-3'><strong>Provider:</strong></Col>
                      <Col className='col-9'>{(content?.provider ?? 'None').toUpperCase()}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col className='col-3'><strong>Region:</strong> </Col>
                      <Col className='col-9'>{variables?.regionToLocationMap?.[content?.region] ?? 'None'} / {content?.region ?? 'None'} </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col className='col-3'><strong>State:</strong> </Col>
                      <Col className='col-9'>{content?.state ?? 'None'}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col className='col-3'>
                        <strong>{(content?.provider ?? 'None').toUpperCase()} ID:</strong>
                      </Col>
                      <Col className='col-9'>
                        {content?.cloud_ids && content.id in content.cloud_ids ? content.cloud_ids[content.id] : 'None'} /
                        {content?.cloud_ids && `connect:${content.id}` in content.cloud_ids ? content.cloud_ids[`connect:${content.id}`] : 'None'}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card>
                <Card.Header className='p-2 ps-3 txt-14'><strong>Network Info</strong></Card.Header>
                <ListGroup variant="flush txt-12">
                  <ListGroup.Item>
                    <Row>
                      <Col className='col-2'><strong>IPv4:</strong></Col>
                      <Col className='col-10'>{content?.ipv4 ?? 'None'}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col className='col-2'><strong>IPv6:</strong> </Col>
                      <Col className='col-10'>{content?.ipv6 ?? 'None'}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col className='col-2'><strong>DNS:</strong> </Col>
                      <Col className='col-10'>{content?.dns?.domain ?? 'None'}</Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={12}>
              <Card>
                <Card.Header className='p-2 ps-3 txt-14'><strong>Share Details</strong></Card.Header>
                <ListGroup variant="flush txt-12">
                  {content?.share?.SS ? content.share.SS.map((ss:any, index:any) => (
                    <ListGroup.Item key={index}>
                      <strong>User {index + 1}:</strong> {getUserNameById(ss) ?? 'Unknown User'}
                    </ListGroup.Item>
                  )) : <ListGroup.Item>Product not shared</ListGroup.Item>}
                </ListGroup>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};


export const AdminGeneralPlatformContentTab: React.FunctionComponent<any> = (props:any) => {
  const { 
    numbers,
    providers,
    messages,
    providerNumbers,
    getProviders,
    getNumbers,
    getMessages,
    isProviderRefreshing,
    getProviderDetails,
    handleAdminSelectObject,
    handleProviderRefresh,
    selectedObject,
    config,
  } = props;


  const [containerHeight, setContainerHeight] = useState<number>(250); // Default height
  const [isLoading, setLoading] = useState(false);

  const [settings, setSettings] = useState<types.DeploymentSettings>(config);
  
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type, checked } = e.target as HTMLInputElement;
    // If it's a checkbox or switch:
    if (type === 'checkbox') {
      setSettings((prev) => ({ ...prev, [name]: checked }));
    } else {
      setSettings((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSettings((prev) => ({
      ...prev,
      [name]: parseInt(value, 10)
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Perform any actions you need here, e.g., calling an API or storing the settings
    helpers.logToOutput('Updated Settings:', settings);
    updateSettings();
  };

  const handleSuperSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Perform any actions you need here, e.g., calling an API or storing the settings
    helpers.logToOutput('Updated Super Settings:', settings);
    updateSuperSettings();
  };

  // Function to update the container height based on window size
  const updateHeight = () => {
    const windowHeight = window.innerHeight;
    const newHeight = windowHeight - 290; // Minus the top and bottom banners etc...
    setContainerHeight(newHeight);
  };


  useEffect(() => {

    updateHeight(); // Set initial height
    window.addEventListener('resize', updateHeight);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  const updateSettings = async () => {
    setLoading(true);

    const adminSettings = [
      'AUTO_HIBERNATE',
      'SEARCH',
      'ENFORCE_SCREEN',
      'DEFAULT_SCREEN_W',
      'DEFAULT_SCREEN_H',
      'DEFAULT_HIBERNATION_TIME',
      'BRANDING',
      'ENABLE_USERNAME'
    ] as const;

    const filteredSettings = adminSettings.reduce((acc, key) => {
      acc[key] = settings[key];
      return acc;
    }, {} as Record<typeof adminSettings[number], string | number | boolean>);

    const bodyToSend = {
      DEPLOYMENT_NAME: 'BOB',
      ...filteredSettings
    };
    
    toast.info('Please wait while we update the configuration details')
    const putResponse = await api.setConfiguration({
      body: bodyToSend,
    });

    if (putResponse?.status === 200) {
      
      toast.success('Settings updated');
      setLoading(false);

    } else {
      
      toast.warn('Sorry something has gone wrong, please try again or contact the support desk')
      setLoading(false);
    }
  };

  const updateSuperSettings = async () => {
    setLoading(true);

    const adminSuperSettings = [
      'ENVIRONMENT',
      'SUPPORT',
      'FILE_EXPLORER',
      'REFERENCE',
      'VPN',
      'KAI'
    ] as const;

    const filteredSettings = adminSuperSettings.reduce((acc, key) => {
      acc[key] = settings[key];
      return acc;
    }, {} as Record<typeof adminSuperSettings[number], string | number | boolean>);

    const bodyToSend = {
      ...filteredSettings
    };
    
    toast.info('Please wait while we update the configuration details')
    const putResponse = await api.setConfiguration({
      body: bodyToSend,
    });

    if (putResponse?.status === 200) {
      
      toast.success('Settings updated');
      setLoading(false);

    } else {
      
      toast.warn('Sorry something has gone wrong, please try again or contact the support desk')
      setLoading(false);
    }
  };

  const handleBrandingDropdownChange = (value: string) => {
    setSettings(prev => ({ ...prev, BRANDING: value }));
  };

  const handleEnvironmentDropdownChange = (value: string) => {
    setSettings(prev => ({ ...prev, ENVIRONMENT: value }));
  };

  const handleSupportDropdownChange = (value: string) => {
    setSettings(prev => ({ ...prev, SUPPORT: value }));
  };

  return (
    <>
      <Row className=''>
        <Col className='col-12'>
          <Row className='m-0'>
            <Col className="">
              <Row className='m-0 p-0'><Col className='m-0 p-0'> <span className='txt-17'>General Settings</span></Col></Row>
              <Row className='m-0 p-0'><Col className='m-0 p-0'> <span className='txt-12'>Modify settings your organisation</span></Col></Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className='mt-2'>
        <Col className="col-12">
          <Row className="m-0 mt-2">
            <Col className="col-12">
              <Tab.Container id="profile-content-pane" defaultActiveKey="admin.GeneralSettings">
                <Row id="k-adm-dash-l5" className='m-0 p-0 mt-1'>
                  <Nav className="pb-1">
                    <Nav.Item className="">
                      <Nav.Link eventKey="admin.GeneralSettings" className="p-0 txt-12">
                        General
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="ms-3">
                      <Nav.Link eventKey="admin.AdvancedSettings" className="p-0 txt-12">
                        Advanced
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Row>
                <hr id="k-adm-dash-l6" className='k-border-top-black mt-0 mb-0'></hr>
                <Row className="mt-0 pe-0 m-0 ">
                  <Tab.Content className='mt-0 pt-1 k-fg-1 k-of-y-auto'>
                    <Tab.Pane eventKey="admin.GeneralSettings">
                      <div className="k-overflow-y-scroll" style={{ height: `${containerHeight}px` }}>
                        <Row>
                          <Col className="col-12 p-0 ">
                            <Row className="m-0 mt-2">
                              <Col className="col-12">
                                <Container className="mb-4 mx-0">
                                  <Form onSubmit={handleSubmit}>
                                    <Row className="mb-3">
                                      <Col md={6}>
                                        <div className='mb-3'>
                                          <span className="p-0 txt-12 txt-light" >
                                            This page allows you to personalise your experience on our online platform. 
                                            Adjust your preferences, update your account details and manage important settings to ensure the service meets your needs. 
                                            Please explore the options below to tailor the platform just the way you like it.
                                          </span>
                                        </div>
                                        <span className="p-0 txt-14 txt-weight-900">Platform Settings</span>
                                        <hr id="k-adm-dash-l6" className='k-border-top-black mt-0 mb-2'></hr>
                                        <Form.Group controlId="branding" className="mb-3">
                                          <Form.Label>
                                            <span className="p-0 txt-12 txt-weight-500">Branding</span><span className="p-0 txt-12 txt-weight-200"> - choose a branding option from the list below</span>
                                          </Form.Label>

                                          <Dropdown>
                                            <Dropdown.Toggle variant="secondary" id="branding-dropdown" className='k-bg-trans color-DA k-p-8 k-wh-100pc k-dropdown-toggle'>
                                              {settings.BRANDING || 'Select your branding'}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item
                                                eventKey="KURO"
                                                onClick={() => handleBrandingDropdownChange('KURO')}
                                              >
                                                KURO
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                eventKey="GENERIC"
                                                onClick={() => handleBrandingDropdownChange('GENERIC')}
                                              >
                                                GENERIC
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                eventKey="NONE"
                                                onClick={() => handleBrandingDropdownChange('NONE')}
                                              >
                                                NONE
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </Form.Group>
                                        <div>
                                          <span className="p-0 txt-12 txt-weight-500">Search</span><span className="p-0 txt-12 txt-weight-200"> - enable or disable the search facility on the front page</span>
                                        </div>
                                        <Form.Check 
                                          type="switch"
                                          id="search"
                                          label="Search"
                                          name="SEARCH"
                                          checked={settings.SEARCH}
                                          onChange={handleInputChange}
                                          className="mb-4"
                                        />

                                        <span className="p-0 txt-14 txt-weight-900">Desktop Settings</span>
                                        <hr id="k-adm-dash-l6" className='k-border-top-black mt-0 mb-2'></hr>

                                        <div>
                                          <span className="p-0 txt-12 txt-weight-500">Username Settings</span>
                                          <span className="p-0 txt-12 txt-weight-200"> - allow users to set their own username</span>
                                        </div>
                                        <Form.Check 
                                          type="switch"
                                          id="enableUsername"
                                          label="Custom Username"
                                          name="ENABLE_USERNAME"
                                          checked={settings.ENABLE_USERNAME}
                                          onChange={handleInputChange}
                                          className="mb-2"
                                        />

                                        <div>
                                          <span className="p-0 txt-12 txt-weight-500">Screen Size</span><span className="p-0 txt-12 txt-weight-200"> - enforce a specific screen size for all users</span>
                                        </div>
                                        <Form.Check 
                                          type="switch"
                                          id="enforceScreen"
                                          label="Enforce Screen Size"
                                          name="ENFORCE_SCREEN"
                                          checked={settings.ENFORCE_SCREEN}
                                          onChange={handleInputChange}
                                          className="mb-2"
                                        />

                                        <Form.Group controlId="defaultScreenW" className="mb-3 k-ps-2-5rem">
                                          <Form.Label>
                                            <span className={settings.ENFORCE_SCREEN ? 'p-0 txt-12 txt-weight-500' : 'p-0 txt-12 txt-weight-500 color-lightText'}>Default Screen Width</span><br/>
                                            <span className={settings.ENFORCE_SCREEN ? 'p-0 txt-12 txt-weight-200' : 'p-0 txt-12 txt-weight-200 color-lightText'}>Specify in pixels the width of the display</span>
                                          </Form.Label>
                                          <Form.Control
                                            className={settings.ENFORCE_SCREEN ? '' : 'color-lightText'}
                                            type="number"
                                            name="DEFAULT_SCREEN_W"
                                            value={settings.DEFAULT_SCREEN_W}
                                            onChange={handleNumberChange}
                                            disabled={!settings.ENFORCE_SCREEN} // Disable if ENFORCE_SCREEN is false
                                          />
                                        </Form.Group>

                                        <Form.Group controlId="defaultScreenH" className="mb-3 k-ps-2-5rem">
                                          <Form.Label>
                                            <span className={settings.ENFORCE_SCREEN ? 'p-0 txt-12 txt-weight-500' : 'p-0 txt-12 txt-weight-500 color-lightText'}>Default Screen Height</span><br/>
                                            <span className={settings.ENFORCE_SCREEN ? 'p-0 txt-12 txt-weight-200' : 'p-0 txt-12 txt-weight-200 color-lightText'}>Specify in pixels the height of the display</span>
                                          </Form.Label>
                                          <Form.Control
                                            className={settings.ENFORCE_SCREEN ? '' : 'color-lightText'}
                                            type="number"
                                            name="DEFAULT_SCREEN_H"
                                            value={settings.DEFAULT_SCREEN_H}
                                            onChange={handleNumberChange}
                                            disabled={!settings.ENFORCE_SCREEN} // Disable if ENFORCE_SCREEN is false
                                          />
                                        </Form.Group>
                                        
                                        <div>
                                          <span className="p-0 txt-12 txt-weight-500">Hibernation </span> <span className="p-0 txt-12 txt-weight-200"> - enable auto hibernation for all users</span>
                                        </div>
                                        <Form.Check 
                                          type="switch"
                                          id="autoHibernate"
                                          label="Auto Hibernate"
                                          name="AUTO_HIBERNATE"
                                          checked={settings.AUTO_HIBERNATE}
                                          onChange={handleInputChange}
                                          className="mb-2"
                                        />

                                        <Form.Group controlId="defaultScreenH" className="mb-3 k-ps-2-5rem">
                                          <Form.Label>
                                            <span className={settings.AUTO_HIBERNATE ? 'p-0 txt-12 txt-weight-500' : 'p-0 txt-12 txt-weight-500 color-lightText'}>Hibernation Wait Time</span><br/>
                                            <span className={settings.AUTO_HIBERNATE ? 'p-0 txt-12 txt-weight-200' : 'p-0 txt-12 txt-weight-200 color-lightText'}>Specify the amount of time before a product will hibernate</span>
                                          </Form.Label>
                                          <Form.Control
                                            className={settings.AUTO_HIBERNATE ? '' : 'color-lightText'}
                                            type="number"
                                            name="DEFAULT_HIBERNATION_TIME"
                                            value={settings.DEFAULT_HIBERNATION_TIME}
                                            onChange={handleNumberChange}
                                            disabled={!settings.AUTO_HIBERNATE} // Disable if AUTO_HIBERNATE is false
                                          />
                                        </Form.Group>
                                        
                                      </Col>
                                    </Row>
                                    <Button variant="primary" type="submit">
                                    Save Changes
                                    </Button>
                                  </Form>
                                </Container>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="admin.AdvancedSettings">
                      <div className="k-overflow-y-scroll" style={{ height: `${containerHeight}px` }}>
                        <Row>
                          <Col className="col-12 p-0 ">
                            <Row className="m-0 mt-2">
                              <Col className="col-12">
                                <Container className="mb-4 mx-0">
                                  <Form onSubmit={handleSuperSubmit}>
                                    <Row className="mb-3">
                                      <Col md={6}>
                                        <div className='mb-3'>
                                          <span className="p-0 txt-12 txt-light" >
                                            The advanced tab allows administrators to enable license restricted features on the platform. 
                                            Adjust system preferences, update your account limits and manage important settings to ensure the service meets your needs. 
                                            Please do not change the option below unless you have an understanding of the impact.
                                          </span>
                                        </div>

                                        <span className="p-0 txt-14 txt-weight-900">Platform Settings</span>
                                        <hr id="k-adm-dash-l6" className='k-border-top-black mt-0 mb-2'></hr>
                                        <Form.Group controlId="envrionment" className="mb-3">
                                          <Form.Label>
                                            <span className="p-0 txt-12 txt-weight-500">Platform Type</span><span className="p-0 txt-12 txt-weight-200"> - platform should be set to Production unless being debugged</span>
                                          </Form.Label>

                                          <Dropdown>
                                            <Dropdown.Toggle variant="secondary" id="branding-dropdown" className='k-bg-trans color-DA k-p-8 k-wh-100pc k-dropdown-toggle'>
                                              {settings.ENVIRONMENT || 'Select your platform type'}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item
                                                eventKey="PRODUCTION"
                                                onClick={() => handleEnvironmentDropdownChange('PRODUCTION')}
                                              >
                                                Production
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                eventKey="DEVELOPMENT"
                                                onClick={() => handleEnvironmentDropdownChange('DEVELOPMENT')}
                                              >
                                                Development
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </Form.Group>

                                        <Form.Group controlId="support" className="mb-3">
                                          <Form.Label>
                                            <span className="p-0 txt-12 txt-weight-500">Support</span><span className="p-0 txt-12 txt-weight-200"> - select the support type for this platform</span>
                                          </Form.Label>

                                          <Dropdown>
                                            <Dropdown.Toggle variant="secondary" id="branding-dropdown" className='k-bg-trans color-DA k-p-8 k-wh-100pc k-dropdown-toggle'>
                                              {settings.SUPPORT || 'Select the support package'}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item
                                                eventKey="ZENDESK"
                                                onClick={() => handleSupportDropdownChange('ZENDESK')}
                                              >
                                                ZenDesk
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                eventKey="NONE"
                                                onClick={() => handleSupportDropdownChange('NONE')}
                                              >
                                                None
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </Form.Group>

                                        <span className="p-0 txt-14 txt-weight-900">Licensed Features</span>
                                        <hr id="k-adm-dash-l6" className='k-border-top-black mt-0 mb-2'></hr>
                                        
                                        <div>
                                          <span className="p-0 txt-12 txt-weight-500">Cloud Storage</span>
                                          <span className="p-0 txt-12 txt-weight-200"> - enable or disable cloud storage for all users</span>
                                        </div>
                                        <Form.Check 
                                          type="switch"
                                          id="fileExplorer"
                                          label="File Explorer"
                                          name="FILE_EXPLORER"
                                          checked={settings.FILE_EXPLORER}
                                          onChange={handleInputChange}
                                          className="mb-2"
                                        />

                                        <div>
                                          <span className="p-0 txt-12 txt-weight-500">Reference</span>
                                          <span className="p-0 txt-12 txt-weight-200"> - enable or disable the reference library </span>
                                        </div>
                                        <Form.Check 
                                          type="switch"
                                          id="reference"
                                          label="Reference"
                                          name="REFERENCE"
                                          checked={settings.REFERENCE}
                                          onChange={handleInputChange}
                                          className="mb-2"
                                        />

                                        <div>
                                          <span className="p-0 txt-12 txt-weight-500">VPN</span>
                                          <span className="p-0 txt-12 txt-weight-200"> - enable or disable the VPN feature </span>
                                        </div>
                                        <Form.Check 
                                          type="switch"
                                          id="vpn"
                                          label="VPN"
                                          name="VPN"
                                          checked={settings.VPN}
                                          onChange={handleInputChange}
                                          className="mb-2"
                                        />

                                        <div>
                                          <span className="p-0 txt-12 txt-weight-500">Kuro AI</span>
                                          <span className="p-0 txt-12 txt-weight-200"> - enable or disable the AI chatbot</span>
                                        </div>
                                        <Form.Check 
                                          type="switch"
                                          id="kai"
                                          label="KAI"
                                          name="KAI"
                                          checked={settings.KAI}
                                          onChange={handleInputChange}
                                          className="mb-2"
                                        />
                                      </Col>
                                    </Row>
                                    <Button variant="primary" type="submit">
                                    Save Changes
                                    </Button>
                                  </Form>
                                </Container>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Row>
              </Tab.Container>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}